import React from "react";
import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";

import { useStrainPageContext } from "context/StrainPageContext";

import Carousel from "components/botanic/Carousel";
import Image from "components/Image";

const MAX_DISPLAYED_PHOTOS = 7;

const StrainPhotos = ({ className = "" }): JSX.Element => {
  const {
    strain: { highlightedPhotos = [], slug, name },
  } = useStrainPageContext();

  const hasMorePhotos = highlightedPhotos?.length >= MAX_DISPLAYED_PHOTOS;

  return (
    <div id="strain-photos-section" className={className}>
      <Carousel
        title={`Photos of ${name}`}
        seeMoreText={hasMorePhotos ? "Show all" : undefined}
        seeMoreHref={hasMorePhotos ? `/strains/${slug}/photos` : undefined}
        showSeeMoreCard={hasMorePhotos}
      >
        {highlightedPhotos.map((photo, i) => (
          <Link
            href={`/strains/${slug}/photos`}
            onClick={() => trackEvent("Photo Card", "Click", "Photo card")}
            className="relative block w-full h-full"
            key={`strain-photo-${i}`}
            aria-label="User submitted photo"
          >
            <Image
              alt={`User uploaded image of ${name}`}
              className="image-card__image"
              src={photo.imageUrl}
              sizes={[240, null, 300, 240, null, 240]}
              ratio="square"
            />
          </Link>
        ))}
      </Carousel>
    </div>
  );
};

export default StrainPhotos;
