import getStrainEffectsDispensaries from "api/requests/consumerApi/getStrainEffectsDispensaries";
import { Coordinates } from "custom-types/Coordinates";
import { Dispensary } from "custom-types/Dispensary";
import { Strain } from "custom-types/Strain";
import { StrainV2 } from "custom-types/StrainV2";
import { extractStrainSensations } from "utils/extractStrainSensations";
import formatImgixUrl from "utils/image/formatImgixUrl";
import logError from "utils/logError";

export const fetchNearbyDispensaries = async (
  strain: Strain | StrainV2 | Record<string, never>,
  coordinates: Coordinates,
  maxDistance: number,
) => {
  const topEffects = extractStrainSensations(strain.effects)
    .map((effect) => effect.name.toLowerCase())
    .slice(0, 3);

  let stores: Dispensary[] = [];

  try {
    const response = await getStrainEffectsDispensaries(strain.slug, {
      boost_strain: true,
      lat: coordinates.lat,
      lon: coordinates.lon,
      top_strain_effects_included: topEffects,
    });
    stores = response.stores;

    stores.forEach((item: Dispensary, i: number) => {
      if (item.logoImage) {
        stores[i].logoImage = formatImgixUrl(item.logoImage);
      }

      if (item.productCategories) {
        const sanitizedProductCategories = item.productCategories.map(
          (category) => {
            if (
              (category && category.name === "Pre Rolls") ||
              (category && category.name === "Pre-rolls")
            ) {
              return { ...category, name: "PreRoll" };
            } else if (category && category.name === "Cartridges") {
              return { ...category, name: "Cartridge" };
            }
            return category;
          },
        );

        stores[i].productCategories = sanitizedProductCategories;
      }
    });
  } catch {
    logError("Error processing data", {
      functionName: "fetchNearbyDispensaries",
    });
  }
  return {
    dispensaries: stores,
    distanceUsed: maxDistance,
  };
};
