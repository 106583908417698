import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";
import { useSelector } from "react-redux";

import {
  DISPENSARY_DEFAULT_MAX_DISTANCE,
  DISPENSARY_EXPANDED_MAX_DISTANCE,
} from "constants/dispensary";
import { Action, Category } from "constants/events";
import { useStrainPageContext } from "context/StrainPageContext";
import { Dispensary } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getUserId } from "redux/selectors/user";
import { getRoundedDistance } from "utils/distance";
import {
  getEffectShoppingUrl,
  getTopEffects,
} from "utils/strains/effectShoppingUtils";

import Carousel from "components/botanic/Carousel";
import DispensaryCardPlaceholder from "components/botanic/DispensaryCard/DispensaryCardPlaceholder";
import InterlockingCircleIcon from "components/Icons/interlocking_circles.svg";
import PickupReadinessDispensaryCard from "components/PickupReadinessDispensaryCard";
import Subtitle from "components/Subtitle";
import TrackImpression from "components/TrackImpression";

import StrainDispensariesCarouselNoResults from "./StrainDispensariesCarouselNoResults";

type Props = {
  dispensaries: Dispensary[] | Record<string, never>[];
  distanceUsed?: number;
};

const StrainDispensariesCarousel = ({
  dispensaries,
  distanceUsed,
}: Props): JSX.Element => {
  const { strain, eventPage } = useStrainPageContext();
  const userId = useSelector(getUserId);

  const { publishEvent } = useEventTracker();

  const distanceLimitReached =
    distanceUsed === DISPENSARY_EXPANDED_MAX_DISTANCE;

  const countryCode = useDomainCountryCode();

  const topEffects = getTopEffects(strain.effects);

  const getTitle = () => {
    const hasExactStrainMatch = dispensaries.some(
      ({ matchedStrainNames = [] }) =>
        matchedStrainNames.some(
          (matchedStrain) => matchedStrain.name === strain.name,
        ),
    );

    return hasExactStrainMatch
      ? `Buy ${strain.name} (or a similar strain)`
      : `Buy strains with similar effects to ${strain.name}`;
  };

  const getSubtitle = () => {
    if (dispensaries.length && !distanceLimitReached) {
      return "Order online. Same-day pickup or delivery in";
    } else if (dispensaries.length && distanceLimitReached) {
      return `Not exactly "nearby" but the closest we could find near `;
    } else {
      return "We couldn’t find any near";
    }
  };

  const getDispensaryUrl = (dispensary: Dispensary | Record<string, never>) => {
    return getEffectShoppingUrl(
      topEffects,
      dispensary.slug,
      dispensary.retailType,
      countryCode,
      strain.name,
    );
  };

  const seeMoreLink = `/dispensaries?strain=${encodeURIComponent(
    strain.name,
  )}&radius=${
    distanceLimitReached
      ? DISPENSARY_EXPANDED_MAX_DISTANCE
      : DISPENSARY_DEFAULT_MAX_DISTANCE
  }mi&sort=distance`;

  return (
    <div data-testid="strain-dispensaries-carousel" className="bg-leafly-white">
      <TrackImpression
        category="Carousel"
        label="dispensary carousel view"
        className="container py-xxl"
      >
        <Carousel
          title={getTitle()}
          subtitle={
            <Subtitle
              subtitle={getSubtitle()}
              changeLocation={true}
              title={`Find ${strain.name} nearby`}
              referrer="strainDispensaryCarousel"
            />
          }
          showSeeMoreCard={false}
          seeMoreCardClass="rounded overflow-hidden shadow-low bg-white"
          noResultsDisplay={<StrainDispensariesCarouselNoResults />}
          seeMoreOnClick={() =>
            trackEvent(
              "Carousel",
              "Click",
              `${eventPage} dispensary carousel show all`,
            )
          }
          cardsPerSlide={4}
          seeMoreHref={seeMoreLink}
          seeMoreText={"See more dispensaries near you"}
        >
          {dispensaries.map((dispensary, i) => {
            if (dispensary.slug) {
              const matchedStrainNames = dispensary.matchedStrainNames || [];
              const isExactMatch = matchedStrainNames.some(
                (matchedStrain) => matchedStrain.name === strain.name,
              );

              return (
                <TrackImpression
                  key={`dispensary-card-${dispensary.id}`}
                  category="dispensary card"
                  label={`${eventPage} dispensary card slot ${i + 1}`}
                  className="h-full w-full relative"
                  trackingFunction={() => {
                    publishEvent({
                      action: Action.impression,
                      category: Category.dispensaryCard,
                      dispensaryDistance: getRoundedDistance(
                        dispensary.distanceMi,
                        countryCode,
                      ),
                      dispensaryId: dispensary.id,
                      label: `strain page dispensary card slot # ${i + 1}`,
                      strainId: strain.id,
                      userId,
                    });
                  }}
                >
                  <PickupReadinessDispensaryCard
                    accessoryTray={
                      <StrainEffectsAccessoryTray
                        strainName={strain.name}
                        isExactMatch={isExactMatch}
                        url={getDispensaryUrl(dispensary)}
                        onClick={() => {
                          publishEvent({
                            action: Action.click,
                            category: Category.dispensaryCard,
                            dispensaryDistance: getRoundedDistance(
                              dispensary.distanceMi,
                              countryCode,
                            ),
                            dispensaryId: dispensary.id,
                            label: `strain page dispensary card slot # ${
                              i + 1
                            }`,
                            strainId: strain.id,
                            userId,
                          });
                        }}
                      />
                    }
                    preserveHeight={true}
                    dispensaryId={dispensary.id}
                    className="block h-full w-full rounded overflow-hidden shadow-low bg-white"
                    currentStatus={dispensary.currentStatuses?.store}
                    deliveryEnabled={dispensary.hasDeliveryEnabled}
                    distanceMi={dispensary.distanceMi}
                    featureTier={dispensary.tier}
                    href={getDispensaryUrl(dispensary)}
                    inStoreCartEnabled={dispensary.inStoreCartEnabled}
                    isNewDispensary={dispensary.isNewDispensary}
                    logoUrl={dispensary.logoImage}
                    name={dispensary.name}
                    pickupEnabled={dispensary.hasReservationsEnabled}
                    preorderConfigured={dispensary.preorderConfigured}
                    rating={dispensary.starRating}
                    reviewCount={dispensary.numberOfReviews}
                    scheduleType="store"
                    slug={dispensary.slug}
                    tags={dispensary.flags}
                    timeZone={dispensary.timeZone}
                    onClick={() => {
                      publishEvent({
                        action: Action.click,
                        category: Category.dispensaryCard,
                        dispensaryDistance: getRoundedDistance(
                          dispensary.distanceMi,
                          countryCode,
                        ),
                        dispensaryId: dispensary.id,
                        label: `strain page dispensary card slot # ${i + 1}`,
                        strainId: strain.id,
                        userId,
                      });
                    }}
                  />
                </TrackImpression>
              );
            } else {
              return (
                <DispensaryCardPlaceholder
                  accessoryTray={true}
                  id={`strain-dispensary-card-placeholder-${i}`}
                  key={`dispensary-card-placeholder-${i}`}
                  className="h-full rounded shadow-low overflow-hidden bg-white"
                />
              );
            }
          })}
        </Carousel>
      </TrackImpression>
    </div>
  );
};

const StrainEffectsAccessoryTray = ({
  isExactMatch,
  strainName,
  url,
  onClick,
}: {
  isExactMatch: boolean;
  strainName?: string;
  url: string;
  onClick: () => void;
}) => {
  // Could move the icon out to its own file ¯\_(ツ)_/¯
  return (
    <Link
      href={url}
      className="w-full border-t border-light-grey mt-md pt-lg pb-sm"
      onClick={onClick}
    >
      <div className="flex justify-center items-center text-xs underline font-bold">
        {isExactMatch ? (
          `Shop ${strainName}`
        ) : (
          <span className="flex flex-row">
            <div className="m-sm">
              <InterlockingCircleIcon width="18" height="12" />
            </div>
            <span className="mr-lg flex items-center">
              Shop similar effects
            </span>
          </span>
        )}
      </div>
    </Link>
  );
};

export default StrainDispensariesCarousel;
