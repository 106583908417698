import { StrainAttributes } from "custom-types/Strain";
import { dispensaryHref } from "utils/dispensaryUrls";
import { extractStrainSensations } from "utils/extractStrainSensations";

const getEffectShoppingUrl = (
  topEffects: string[],
  dispensarySlug: string,
  retailType: string,
  countryCode: string,
  strainName?: string,
) => {
  // add effects to the url for menu filtering
  const effectsEncoded = topEffects
    .map((effect: string) => `top_strain_effects_included[]=${effect}`)
    .join("&");

  // add the strain name and boost results
  const strainBoost = strainName ? `&boost_strain=${strainName}` : "";

  const href = dispensaryHref({
    additionalPath: `/menu?${effectsEncoded}${strainBoost}`,
    countryCode,
    retailType,
    slug: dispensarySlug,
  });

  return href;
};

const getTopEffects = (effects: StrainAttributes) =>
  extractStrainSensations(effects)
    .map((effect) => effect.name.toLowerCase())
    .slice(0, 3);

export { getEffectShoppingUrl, getTopEffects };
