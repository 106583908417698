import { StrainAttribute } from "custom-types/Strain";

export const createStrainSensationsSchema = (
  strainName: string,
  totalReviewers: number,
  feelings: StrainAttribute[],
  helpsWith: StrainAttribute[],
  negatives: StrainAttribute[],
) => ({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    feelings.length > 0
      ? {
          "@type": "Question",
          acceptedAnswer: {
            "@type": "Answer",
            text: `Based on ${strainName} strain reviews, users reported ${strainName} makes you feel:
        <ul>
          ${feelings.map(
            (feeling) =>
              `<li>${feeling.name}: ${Math.floor(
                ((feeling.votes ?? 0) / totalReviewers) * 100,
              )}%</li>`,
          )}
        </ul>`,
          },
          name: `How does ${strainName} strain make you feel?`,
        }
      : null,
    helpsWith.length > 0
      ? {
          "@type": "Question",
          acceptedAnswer: {
            "@type": "Answer",
            text: `Based on [${strainName} strain reviews, users reported ${strainName} helps with:
        <ul>
          ${helpsWith.map(
            (item) =>
              `<li>
            ${item.name}: ${Math.floor(
              ((item.votes ?? 0) / totalReviewers) * 100,
            )}%
          </li>`,
          )}
        </ul>
        `,
          },
          name: `What does ${strainName} strain help with?`,
        }
      : null,
    negatives.length > 0
      ? {
          "@type": "Question",
          acceptedAnswer: {
            "@type": "Answer",
            text: `Based on ${strainName} strain reviews, users reported the negative effects of ${strainName} are:
       <ul>
          ${negatives.map(
            (negative) =>
              `<li>${negative.name}: ${Math.floor(
                ((negative.votes ?? 0) / totalReviewers) * 100,
              )}%</li>`,
          )}
       </ul>
      `,
          },
          name: `What are the negative effects of ${strainName} strain?`,
        }
      : null,
  ].filter(Boolean),
});
