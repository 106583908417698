import { stringify } from "qs";

import consumerApi from "api/services/consumer-api";
import { Dispensary } from "custom-types/Dispensary";
import logError from "utils/logError";

type MatchingStrainVariant = {
  dispensary: Dispensary;
  distanceMi: number;
  dispensaryRetailType: string;
  matchedStrainNames: {
    name: string;
  }[];
};

const mapDispensaryVariant = (variant: MatchingStrainVariant) => {
  const { dispensary, matchedStrainNames, dispensaryRetailType } = variant;

  return {
    ...dispensary,
    flags: dispensary.tags,
    hasDeliveryEnabled: dispensary.deliveryEnabled,
    logoImage: dispensary.logoUrl,
    matchedStrainNames,
    numberOfReviews: dispensary.reviewCount,
    retailType: dispensaryRetailType,
    starRating: dispensary.rating,
  };
};

const getStrainEffectsDispensaries = async (
  strainSlug: string,
  {
    lat,
    lon,
    boost_strain,
    top_strain_effects_included,
  }: {
    lat: number;
    lon: number;
    boost_strain: boolean;
    top_strain_effects_included: string[];
  },
): // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
Promise<any> => {
  try {
    const { data } = await consumerApi.get(
      `/api/dispensaries/v2/matching_strains/${strainSlug}`,
      {
        params: {
          boost_strain,
          filter: { max_distance: 20, top_strain_effects_included },
          lat,
          lon,
          skip: 0,
          take: 8,
        },
        paramsSerializer: (params) => {
          return stringify(params, { arrayFormat: "brackets" });
        },
      },
    );
    return {
      stores: data?.data?.map(mapDispensaryVariant),
    };
  } catch (e) {
    logError(e.message, {
      functionName: "getStrainEffectsDispensaries",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return {
      stores: [],
    };
  }
};

export default getStrainEffectsDispensaries;
