import React, { useEffect } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import classNames from "classnames";
import Link from "next/link";

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  disabled?: boolean;
  element?: "link" | "button";
  eventCategory?: string;
  eventLabel?: string;
  link?: string;
  onClick?: (title: string) => void;
  selected?: boolean;
  title: string;
  trackClick?: boolean;
  trackImpression?: boolean;
};

const IconTile = ({
  children,
  className = "",
  disabled = false,
  eventCategory,
  eventLabel,
  link,
  onClick,
  selected = false,
  title,
  trackClick = true,
  trackImpression = true,
}: Props) => {
  useEffect(() => {
    trackImpression &&
      eventCategory &&
      trackEvent(eventCategory, "Impression", eventLabel || title);
  }, []);

  const ClickElement = link ? Link : "button";
  const linkProps = link && !disabled ? { href: link } : {};
  return (
    <>
      <ClickElement
        {...(linkProps as { href: string })}
        className={classNames(
          "flex flex-col items-center block py-lg px-1.5 rounded text-center w-full h-full justify-center overflow-hidden transition-colors",
          {
            "border-2": !link && selected,
            "border-2 border-light-grey": !link && !selected,
            "shadow-low": link,
            "text-grey cursor-default": disabled,
          },
          className,
        )}
        onClick={() => {
          if (!disabled) {
            onClick && onClick(title);
            trackClick &&
              eventCategory &&
              trackEvent(eventCategory, "Click", eventLabel || title);
          }
        }}
        data-testid={`icon-tile-link${
          disabled ? "-disabled" : selected ? "-selected" : ""
        }`}
      >
        <>
          {children}
          <p
            className="text-sm font-bold text-center mt-xs mb-none truncate w-full lowercase"
            data-testid="item-name"
          >
            {title}
          </p>
        </>
      </ClickElement>
    </>
  );
};

export default IconTile;
