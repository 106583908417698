import React, { useState } from "react";
import { useSelector } from "react-redux";

import deleteReviewVote from "api/requests/deleteReviewVote";
import postFlagReview from "api/requests/postFlagReview";
import postReviewVote from "api/requests/postReviewVote";
import { StrainReview } from "custom-types/Reviews";
import { StrainV2 } from "custom-types/StrainV2";
import { useSsoUrls } from "hooks/useSsoUrls";
import { getIsLoggedIn } from "redux/selectors/user";

import ReviewCard from "components/botanic/ReviewCard";

const StrainReviewCardContainer: React.FC<{
  review: StrainReview;
  truncate?: boolean;
  strain: StrainV2;
}> = ({ review, truncate, strain }) => {
  const {
    created,
    rating,
    reportedFeelings,
    reportedNegatives,
    text,
    upvotesCount,
    user,
    username,
  } = review;

  const { name } = strain || {};

  const [userVoted, setUserVoted] = useState(false);
  const [userReported, setUserReported] = useState(false);
  const [numberOfVotes, setNumberOfVotes] = useState(upvotesCount || 0);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { signInUrl } = useSsoUrls();

  const addVote = async () => {
    const postReviewSuccess = await postReviewVote(review.id);

    if (postReviewSuccess) {
      setNumberOfVotes(numberOfVotes + 1);
      setUserVoted(true);
    }
  };

  const deleteVote = async () => {
    const deleteReviewSuccess = await deleteReviewVote(review.id);

    if (deleteReviewSuccess) {
      setNumberOfVotes(numberOfVotes - 1);
      setUserVoted(false);
    }
  };

  const toggleVote = async () => {
    if (isLoggedIn) {
      userVoted ? deleteVote() : addVote();
    } else {
      redirectToLogin();
    }
  };

  const reportReview = () => {
    if (isLoggedIn) {
      if (userReported) {
        return;
      }
      postFlagReview(review.id, "strain").then((success) => {
        if (success) {
          setUserReported(true);
        }
      });
    } else {
      redirectToLogin();
    }
  };

  const redirectToLogin = () => {
    window.location.href = signInUrl;
  };

  return (
    <div className="py-sm">
      <ReviewCard
        created={created}
        hasReported={userReported}
        hasUserVoted={userVoted}
        itemReviewed={name}
        rating={rating}
        reportAction={reportReview}
        reportedFeelings={reportedFeelings.concat(reportedNegatives)}
        text={text}
        upvoteCount={numberOfVotes}
        username={username}
        userPublicId={user?.publicId}
        voteAction={toggleVote}
        truncate={truncate}
      />
    </div>
  );
};

export default StrainReviewCardContainer;
