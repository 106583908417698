import React from "react";

import NoResults from "components/NoResults";

const StrainDispensariesCarouselNoResults = (): JSX.Element => (
  <NoResults sectionName="strain-dispensaries-carousel">
    <p>We couldn’t find this strain within 150 miles of your location.</p>
    <p>
      But, we used science to find{" "}
      <a
        href="#similar-strains-section"
        className="no-underline font-bold text-green"
      >
        these strains with similar effects
      </a>
      . Yeah, science!
    </p>
  </NoResults>
);

export default StrainDispensariesCarouselNoResults;
